.autoUrgentSimilar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 155px;
  left: 10px;
  background: rgba(255, 0, 0, 0.4);
  border-radius: 5px;
  padding: 3px 10px;
}

.autoUrgentSimilar > span {
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

