.createPage {
  width: 100%;
  background: #f5f5f5;
  padding: 20px 0;
  min-height: 650px;
}

.createAnnouncement {
  width: 92%;
  max-width: 1264px;
  margin: 25px auto;
}

.topStepper {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin: 25px auto;
}

.topStepperName {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.topStepperName > h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

@media screen and (max-width: 768px) {
  .createAnnouncement {
    width: 90%;
    margin: 0 auto;
  }
  .topStepper {
    flex-direction: column;
    gap: 30px;
    margin: 0 auto 25px auto;
  }
}
