.navigation {
  width: 92%;
  max-width: 1264px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
}

.mainNavs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.eachMainNav > svg {
  cursor: pointer;
}

.navGreenButton {
  background: #88be45;
  border-radius: 10px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  height: 32px;
  padding: 4px 15px;
  min-width: max-content;
  line-height: 24px;
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 24px;
}

.navGreyButton {
  background: #ebebeb;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
}

.navigationShadow {
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  padding: 20px 0;
  background: #fff;
  z-index: 2;
  position: relative;
}

.mobileNavs {
  display: none;
}

.mobileNavigation {
  display: none;
}

.openCategories {
  background: #fff;
  position: absolute;
  padding: 24px 0;
  opacity: 1;
  width: 100%;
  overflow: hidden;
  visibility: visible;
  transform: translate3d(0px, 0px, 0px);
  transition: opacity 0.2s linear 0s, transform 0.2s linear,
    visibility 0s linear;
}

.closedCategories {
  background: #fff;
  overflow: hidden;
  position: absolute;
  padding-bottom: 24px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0px, -8px, 0px);
  transition: opacity 0.1s linear 0s, transform 0.1s linear 0s,
    visibility 0s linear 0.1s;
}

.eachMainCat {
  width: 270px;
  height: 35px;
  border-radius: 6px 0px 0px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eachMainCat:hover {
  background: #f5f5f5;
}

.eachMainCat > a {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  text-decoration: none;
}

.mainNavLeft {
  display: flex;
  gap: 20px;
  align-items: center;
}

.myAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mobileTopAvatar {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 1024px) {
  .mainNavs {
    display: none;
  }
  .mobileNavigation {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .mobileNavs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 830px) {
  .navGreyButton {
    padding: 10px;
  }
  .navGreenButton {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .mobileCamera {
    width: 30px;
    height: 30px;
    background: #ebebeb;
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navigation {
    width: 90%;
  }
}
