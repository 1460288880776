.top {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  padding: 20px 0;
}

.eachMenuTop {
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  background: #ffffff;
  flex: 1;
  max-width: 210px;
  min-width: 174px;
}

.eachMenuTop > img {
  height: 180px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}

.topPadding > p {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.topPadding > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.mainTop {
  width: 100%;
  margin: auto;
  background: #fffbe7;
}

.topLocation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 4px 20px;
}

.topLocation > span {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.topFavorite {
  position: absolute;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 155px;
  cursor: pointer;
}

.topPadding {
  padding: 0 10px 20px;
}

.prevArrTop {
  z-index: 1;
  left: -20px;
  top: 35%;
}

.nextArrTop {
  z-index: 1;
  right: -20px;
  top: 35%;
}

.topArrowsSlider {
  position: absolute;
  width: 100%;
  top: 150px;
}

.topMain {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 92%;
  margin: auto;
  position: relative;
  max-width: 1264px;
  padding: 20px 0;
}

.eachTopDiv {
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  background: #ffffff;
  min-width: 218px;
  width: 218px;
  height: 256px;
}

.eachTopDiv > img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}

.eachTopFav {
  position: absolute;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 155px;
  cursor: pointer;
}

.slideInner___2mfX9 {
  height: fit-content !important;
}

.topDisplay {
  display: flex;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
}

@media screen and (max-width: 1024px) {
  .eachMenuTop {
    min-width: 170px;
  }
  .autoAndApartment {
    flex-direction: column;
    width: 90%;
  }
  .autoAndApartmentLeftPart {
    width: 100%;
  }
  .autoAndApartmentRightPart {
    display: none;
  }
  .topMain {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .eachMenuTop > img {
    height: 130px;
  }
  .topPadding > p {
    font-size: 14px;
    line-height: 19px;
  }
  .topPadding > span {
    font-size: 12px;
    line-height: 16px;
  }
  .findUs {
    align-items: flex-start;
  }
  .eachMenuTop {
    min-width: 147px;
  }
  .topFavorite {
    top: 170px;
    width: 25px;
    height: 25px;
  }
  .topFavorite > svg {
    width: 15px;
    height: 15px;
  }
  .eachTopDiv {
    min-width: 147px;
    width: 147px;
    height: 182px;
  }
  .eachTopFav {
    top: 120px;
    width: 25px;
    height: 25px;
  }
  .eachTopFav > svg {
    width: 15px;
    height: 15px;
  }
}

/* @media screen and (max-width: 768px) {
  .mainTop {
    height: 280px;
  }
} */

@media screen and (max-width: 425px) {
  .eachTopFav {
    top: 105px;
  }
  .eachTopDiv {
    width: 140px;
    height: 182px;
  }
  .autoAndApartmentLeftPart {
    width: 100%;
  }
  .topMain {
    width: 90%;
  }
  .swiper-button-next:after {
    display: none;
  }
  .swiper-button-prev:after {
    display: none;
  }
}
