.selectedTab > span {
  font-weight: 400 !important;
}

.eachTabOption > span {
  font-weight: 400 !important;
}

.tabOtherOptions {
  background: #f5f5f5;
  border-radius: 8px;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: absolute;
  z-index: 1;
}

#mobileTabs {
  border: 2px solid #f5f5f5;
}

@media screen and (max-width: 425px) {
  .css-1gsv261 {
    border: none !important;
  }
}
