.autoService > h2 {
  cursor: pointer;
}

.autoUrgent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 155px;
  left: 10px;
  background: rgba(255, 0, 0, 0.4);
  border-radius: 5px;
  padding: 3px 10px;
}

.autoUrgent > span {
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.urgentTag {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(255, 0, 0, 0.4);
  border-radius: 5px;
  padding: 3px 10px;
}

.urgentTag > span {
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.favoriteTag {
  position: absolute;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  bottom: -20px;
  cursor: pointer;
  box-shadow: 0 2px 9px rgba(0,0,0,.15);
}

.buyTag {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(36, 111, 255, 0.4);
  border-radius: 5px;
  padding: 3px 10px;
}

.buyTag > span {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
}

.locationTag {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 4px 20px;
}

.locationTag > span {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.eachMenuAuto > img {
  height: 180px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  object-fit: cover;
}

@media screen and (min-width: 1024px) {
  .eachMenuAuto {
    width: 23.5%;
  }
  .autoServiceSlider {
    gap: 15px 2%;
  }
}

@media screen and (max-width: 768px) {
  .buyMe {
    top: 105px;
  }
}

@media screen and (max-width: 578px) {
  .eachMenuAuto {
    width: 47% !important;
  }
  .favoriteTag {
    width: 25px;
    height: 25px;
    bottom: -10px;
  }
  .favoriteTag > svg {
    width: 15px;
    height: 15px;
  }
}
