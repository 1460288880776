.catalog {
  width: 92%;
  margin: 0 auto;
  padding: 30px 0;
  max-width: 1264px;
}

.catalogAds > img {
  width: 100%;
  height: 86px;
}

.catalog > h1 {
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  word-break: break-word;
}

.mainCatalog {
  display: flex;
  gap: 20px;
}

.filter {
  border: none;
  background: none;
  margin: 5px 0;
  align-self: flex-end;
  outline: none;
  color: #333333;
  cursor: pointer;
}

.changeCatTop {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
