.main {
  position: relative;
  width: 100%;
}

.mainAndBusiness {
  width: 92%;
  margin: auto;
  max-width: 1264px;
}

.autoAndApartment {
  width: 92%;
  max-width: 1264px;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.autoAndApartmentLeftPart {
  width: 80%;
}

.autoAndApartmentRightPart {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 60px;
}

.webAd > img {
  width: 100%;
  height: auto;
}

.findUs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.findUs > h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.findUs > a {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #a7a7a7;
}

.mobileAd {
  display: none;
}

.webAd {
  max-width: 240px;
}

@media screen and (max-width: 1024px) {
  .webAd {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .mobileAd {
    display: flex;
    width: 100%;
    height: auto;
  }
  .mobileAd > img {
    width: 100%;
    height: auto;
  }
  .webAd > img {
    display: none;
  }
  .mainAndBusiness {
    width: 90%;
  }
}
