.mobileSidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
}

#mobileSidebar {
  background: #f5f5f5;
}

.selectedMenu {
  display: flex;
  align-items: center;
  gap: 10px;
}

.selectedMenu > img {
  width: 20px;
  height: 20px;
}

.selectedMenu > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #7791f7;
}

.MobileSidebar {
  width: 100%;
}

.sidebarOtherOptions {
  background: #f5f5f5;
  border-radius: 8px;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: absolute;
  z-index: 2;
}

.eachSidebarOption {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 8px;
}

.eachSidebarOption > img {
  width: 20px;
  height: 20px;
}

.eachSidebarOption > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
