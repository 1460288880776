input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  font: inherit;
  width: 20px;
  height: 20px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  display: grid;
  place-content: center;
  position: relative;
}

input[type="checkbox"]::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0.65rem;
  height: 0.65rem;
  background: #fff;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked {
  background: #7791f7;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #a7a7a7;
}

textarea::placeholder {
  color: #969696;
  font-size: 14px;
}

* {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  width: 10px;
  display: flex;
}

.rightCenter::-webkit-scrollbar {
  display: flex;
  width: 10px;
}

.rightCenter::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}

textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: red;
}

.blueButton {
  background: #7791f7;
  border-radius: 10px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.redButton {
  background: #ff543e;
  border-radius: 10px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.css-1hskriy {
  width: inherit !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #7791f7 !important;
}

.css-n7tliy-MuiStep-root {
  padding: 0 !important;
}

.swiper-slide,
swiper-slide {
  width: fit-content !important;
  height: fit-content !important;
  margin-right: 10px !important;
}

/* Next */
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  background: white !important;
  padding: 10px !important;
  border-radius: 10px !important;
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15) !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #7791f7 !important;
  font-weight: 600 !important;
  width: 50px !important;
  height: 50px !important;
}

/* Prev */
.swiper-button-prev:after,
.swiper-rtl .swiper-button-prev:after {
  background: white !important;
  padding: 10px !important;
  border-radius: 10px !important;
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15) !important;
}

.swiper-button-prev:after,
.swiper-button-prev:after {
  font-size: 20px !important;
}

.swiper-button-prev,
.swiper-button-prev {
  color: #7791f7 !important;
  font-weight: 600 !important;
  width: 50px !important;
  height: 50px !important;
}

.css-0 {
  width: 70% !important;
}

.buttonBack___1mlaL,
.buttonFirst___2rhFr,
.buttonLast___2yuh0,
.buttonNext___2mOCa,
.buttonNext___3Lm3s,
.dot___3c3SI {
  border: none !important;
}

@media screen and (max-width: 768px) {
  .css-1hv8oq8-MuiStepLabel-label {
    font-size: 12px !important;
  }
  .css-0 {
    width: 100% !important;
  }
}
