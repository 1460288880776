.navCategories {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #7791f7;
  border-radius: 10px;
  color: #ffffff;
  gap: 5px;
  padding: 5px 13px;
  cursor: pointer;
}

.navCategories > span {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  line-height: 24px;
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 24px;
}
