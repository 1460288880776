.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  gap: 10px;
  padding-left: 10px;
  height: 34px;
}

.search > input {
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
}

.search > input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #a7a7a7;
}

.search > button {
  width: 89px;
  height: 34px;
  background: #ebebeb;
  border-radius: 0px 10px 10px 0px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  padding: 6px 15px 7px;
  font-size: 14px;
  font-weight: 400;
}

.catalogHam {
  display: flex;
}
