.payViaIdram {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.payViaIdram > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.payViaIdram > p > span {
  color: #7791f7;
}

.idramPayment {
  display: flex;
  gap: 10px;
}

.idramPayment > input {
  width: 233px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 15px;
  outline: none;
}

.idramPayment > button {
  max-width: 200px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

@media screen and (max-width: 425px) {
  .idramPayment {
    flex-direction: column;
  }
  .idramPayment > button {
    max-width: 230px;
    padding: 6px;
  }
  .idramPayment > input {
    width: 100%;
  }
}
