.createTemplate {
  background: #ffffff;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.eachTemplateItem {
  display: flex;
  gap: 30px;
}

.seeMyAnnouncement {
  width: 244px;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
