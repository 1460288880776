.messages {
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 100%;
  height: 590px;
  display: flex;
  margin-bottom: 30px;
}

.messageLeftSide {
  width: 25%;
  min-width: 170px;
  height: 100%;
  border-right: 1px solid #ebebeb;
}

.messageRightSide {
  width: 75%;
}

.mobileTop {
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
}

.mobileTop > h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.backToMessages {
  position: absolute;
  top: 0;
  left: 0;
}

#whoToMessage {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #969696;
}
