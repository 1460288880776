.catalogFilter {
  width: 50%;
  max-width: 296px;
  margin-top: 50px;
}

.subCategory {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #ebebeb;
  padding: 15px 0;
}

.subCategory > h3 {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.subCategory > span {
  color: #7791f7;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
}

.brand > label {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  display: flex;
  align-items: center;
  gap: 5px;
}

.subCategory > label {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  gap: 5px;
  cursor: pointer;
  width: fit-content;
}

.subCategory > label > input {
  color: #6585ff;
  width: 19px;
  height: 19px;
  border-radius: 10px;
  accent-color: currentColor;
}

.searchBrand {
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 5px;
}

.searchBrand > input {
  outline: none;
  border: none;
  background: none;
}

.categoriesAds {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.findInCategories {
  margin: 30px 0 20px 0;
}

/* .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background: #7791f7 !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #ffffff !important;
} */

.radioButtonSelected {
  width: 20px;
  height: 20px;
  background: #7791f7;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radioButtonSelected > div {
  background: #ffffff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.radioButton {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid #ebebeb;
}

@media screen and (max-width: 768px) {
  .catalogFilter {
    display: none;
  }
}
