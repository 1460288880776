.memberRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rightTop {
  padding: 15px 15px 0 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rightTopLeft {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rightTopLeft > img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.rightTopLeft > h2 {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #7791f7;
}

.rightInnerTop {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.rightBottom {
  background: #fafafa;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.chatInput {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  width: 100%;
  outline: none;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.cursor {
  cursor: pointer;
}

.rightCenter {
  width: 100%;
  padding: 0 20px 20px 20px;
  height: 405px;
  overflow-y: auto;
}

.eachMessage {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.eachMessageLeft {
  display: flex;
  gap: 10px;
}

.messageText {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.messageText > div {
  width: 330px;
}

.eachMessageLeft > img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.messageText > h2 {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #7791f7;
}

.messageText > p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #969696;
  word-break: break-word;
}

.eachMessage > span {
  font-weight: 400;
  font-size: 8px;
  line-height: 11px;
  color: #969696;
}

.rightBottom > div {
  position: relative;
  cursor: pointer;
}

.rightBottom > div > label {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 20px;
}

.rightBottom > div > label > input {
  visibility: hidden;
}

.messageText > img {
  width: 150px;
  height: 150px;
}

.photoListDiv {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 10px 30px;
  background: #fafafa;
  width: 100%;
}

.photoList > img {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 425px) {
  .messageText > div {
    width: 230px;
  }
}

@media screen and (max-width: 375px) {
  .messageText > div {
    width: 170px;
  }
}
