.createCategories {
  background: #ffffff;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 15px;
  max-width: 380px;
  height: fit-content;
}

.eachCreateCat {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 15px;
}

.eachCreateCatRad {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.eachCreateCat > p {
  color: #7791f7;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
}

.eachCreateCat > img {
  width: 20px;
  height: 20px;
}

.createCategoryPage {
  display: flex;
  gap: 20px;
}

.createMobileCategoryPage {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.eachMobileCreateCat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
  border-bottom: 1px solid #f5f5f5;
}

.eachMobileCreateCatLeft {
  display: flex;
  align-items: center;
  gap: 10px;
}

.eachMobileCreateCatLeft > img {
  width: 20px;
  height: 20px;
}

.eachMobileCreateCatLeft > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
