.forgotInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px 0;
}

.forgotInfo > span {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}
