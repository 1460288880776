.menuCategories {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.menuCategories > h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  cursor: pointer;
  width: fit-content;
}

.menuCategorySlider {
  display: flex;
  overflow-x: auto;
  gap: 30px;
  padding-bottom: 40px;
}

.eachMenuCategory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 101px;
  height: 101px;
  background: #ebebeb;
  border-radius: 50%;
}

.hoverBg {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.hoverDiv {
  width: 125px;
  height: 145px;
  position: absolute;
  z-index: 1;
}

.hoverBg > span {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.eachMenuCategory > img {
  width: 120px;
  height: 85px;
}

.eachMenuCategory > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.categoriesArrows {
  position: relative;
}

.infiniteBusinessSlider {
  display: flex;
  align-items: center;
  gap: 20px;
}

.carousel {
  width: 100% !important;
}

.horizontalSlider___281Ls {
  overflow: unset !important;
}

.mainMenuCat {
  display: flex;
  gap: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 10px;
}

@media screen and (max-width: 768px) {
  .menuCategories {
    height: 190px;
  }
  .menuCategories > h2 {
    font-size: 20px;
    line-height: 27px;
  }
  .menuCategorySlider {
    gap: 20px;
  }
  .mainMenuCat {
    overflow-y: hidden;
  }
}
