.receivedReviews {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.eachReceivedReview {
  background: #ffffff;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.eachReceivedRating {
  display: flex;
  align-items: center;
  gap: 5px;
}

.eachReceivedRating > span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.eachReviewLeft {
  display: flex;
  gap: 10px;
}

.eachReviewLeft > img {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.reviewDetails {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.reviewDetails > h1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7791f7;
}

.reviewDetails > h2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #969696;
}

.reviewDetails > h3 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  word-break: break-all;
}
