.businessCategories {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 20px;
  position: relative;
}

.pageTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pageTitle > h2 {
  cursor: pointer;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
}

.pageTitle > svg {
  cursor: pointer;
}

.eachMenuBusiness {
  width: 139px;
  height: 139px;
  cursor: pointer;
}

.businessRating > svg {
  cursor: pointer;
}

.businessArrows {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  height: 170px;
  position: relative;
}

.prevArrBusiness {
  left: -20px;
  top: 35%;
  z-index: 1;
}

.nextArrBusiness {
  right: -15px;
  top: 35%;
  z-index: 1;
}

.eachBusDiv {
  position: absolute;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 70px;
  cursor: pointer;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
}

.eachBusDivLeft {
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 70px;
  cursor: pointer;
}

.slick-track {
  display: flex !important;
  gap: 10px !important;
}

.slick-prev:before {
  content: none !important;
}

.slick-next:before {
  content: none !important;
}

.slick-prev {
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px !important;
  z-index: 1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  left: -15px !important;
}

.slick-next {
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px !important;
  z-index: 1 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  right: -15px !important;
}

@media screen and (max-width: 768px) {
  .businessCategories > h2 {
    font-size: 20px;
    line-height: 27px;
  }
  .eachMenuBusiness {
    width: 110px;
    height: 110px;
  }
  .eachBusDiv {
    display: none;
  }
  .eachBusDivLeft {
    display: none;
  }
}
