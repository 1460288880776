.switchFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  gap: 20px;
  width: 100%;
}

.switchFilter > h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
