.filterHamTop {
  width: 100%;
  height: 50px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterHamTop > h1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.blueCloseIcon {
  position: absolute;
  left: 16px;
  top: 15px;
  display: flex;
  align-items: center;
}
