.singleSeller {
  display: flex;
  gap: 20px;
  width: 92%;
  max-width: 1264px;
  margin: 0 auto;
  padding-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .singleSeller {
    flex-direction: column;
    width: 90%;
  }
}
