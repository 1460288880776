.profileLayout {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 92%;
  margin: 0 auto;
  max-width: 1264px;
}

.profileLayoutOutline {
  width: 92%;
}

.mobileSidebarDisplay {
  display: none;
}

.sidebarDisplay {
  display: flex;
}

@media screen and (max-width: 768px) {
  .profileLayout {
    width: 90%;
  }
  .profileLayoutOutline {
    width: 100%;
  }
  .mobileSidebarDisplay {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 10px auto;
  }
  .sidebarDisplay {
    display: none;
  }
}
