.mainSlider {
  position: relative;
  overflow: hidden;
  max-width: 1264px;
  margin: 30px auto;
  height: 150px;
}

.slider {
  display: flex;
  margin: 40px 0;
  gap: 20px;
  position: relative;
  overflow: hidden;
  max-width: 1264px;
  width: 100%;
  margin: auto;
}

.eachSlide {
  width: 95%;
  height: 150px;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.eachSlide > span {
  position: absolute;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  bottom: 30px;
  left: 20px;
}

.eachSlide > img {
  width: 98px;
  height: 98px;
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.mainSliderPrev {
  left: -20px;
  top: 52%;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
}

.slick-arrow {
  background: #ffffff !important;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15) !important;
}

.mainSliderNext {
  right: -20px;
  top: 52%;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
}

.styles-module_item-tracker__3bypy {
  gap: 20px;
}

.styles-module_item-container__a8zaY {
  width: 380px !important;
}

.mainSliderArrows {
  width: 92%;
  position: relative;
  top: 60px;
  margin: auto;
  max-width: 1264px;
}

#previousSliderArrow {
  top: 36%;
  left: 0;
}

#nextSliderArrow {
  top: 36%;
  right: 0;
}

@media screen and (max-width: 1366px) {
  #previousSliderArrow {
    left: 20px;
  }
  #nextSliderArrow {
    right: 20px;
  }
}

@media screen and (max-width: 1366px) {
  #previousSliderArrow {
    left: 40px;
  }
  #nextSliderArrow {
    right: 40px;
  }
}

@media screen and (min-width: 1264px) {
  .mainSliderArrows {
    width: 100%;
  }
}

@media screen and (max-width: 1264px) {
  .mainSliderArrows {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  #previousSliderArrow {
    left: 40px;
  }
  #nextSliderArrow {
    right: 40px;
  }
}

@media screen and (max-width: 768px) {
  .mainSliderArrows {
    width: 90%;
    top: 100px;
  }
  .slider {
    margin: 40px 20px;
  }
  .eachSlide {
    width: 90%;
    margin: auto;
  }
  .eachSlide > img {
    width: 80px;
    height: 80px;
  }
  .eachSlide > span {
    font-size: 18px;
    line-height: 25px;
  }
  .mainSliderNext {
    display: none;
  }
  .mainSliderPrev {
    display: none;
  }
  .mainSlider {
    margin: 20px auto;
  }
}
