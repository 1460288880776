.profileInfo {
  background: #ffffff;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
}

.eachProfileSetting {
  display: flex;
  width: 100%;
  gap: 30px;
}

.eachProfileSetting > span {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #969696;
  width: 15%;
  min-width: 120px;
}

.eachProfileSetting > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #7791f7;
  cursor: pointer;
}

.changeAvatar {
  display: flex;
  gap: 10px;
  max-width: 430px;
  width: 60%;
}

.changeAvatar > img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.changeAvatar > label {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #7791f7;
}

.changeAvatar > input {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  background: none;
}

.changeType {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 300px;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding-right: 10px;
}

.changeType > input {
  border: none;
  outline: none;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  background: none;
}

.editName {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.changeAvatar > select {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  outline: none;
}

.changeAvatar > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

#profSetting {
  justify-content: space-between;
}

#profSetting > div {
  gap: 50px;
}

#profSetting > div > span {
  min-width: 120px;
  width: 15%;
  color: #969696;
}

.selectRegion {
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  cursor: pointer;
}

.currentRegion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.currentRegion > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.regionDropdown {
  position: absolute;
  width: 100%;
  background: #ffffff;
  left: 0;
  top: 40px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  z-index: 1;
}

.eachRegion {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
}

.eachRegion:hover {
  border-radius: 8px;
  background: #f7f7f7;
}

.changeRegion {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 300px;
  width: 60%;
}

@media screen and (min-width: 1650px) {
  #profSetting > div {
    gap: 20%;
  }
}

@media screen and (max-width: 1650px) {
  #profSetting > div {
    gap: 10%;
  }
  .changeRegion {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .eachProfileSetting {
    flex-direction: column;
  }
  .eachProfileSetting {
    align-items: flex-start;
  }
  #profSetting > p {
    display: flex;
    width: 100%;
    justify-content: end;
  }
  .eachProfileSetting {
    gap: 10px;
  }
  #profSetting > div {
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: flex-start;
  }
}
