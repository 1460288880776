.catalogItems {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px 2%;
}

.catalogItems > img {
  width: 100%;
}

.changeCat {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  max-width: 947px;
}

.changeCat > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #969696;
}

.eachMenuCatalog {
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  background: #ffffff;
  width: 23%;
}

.eachMenuCatalog > img {
  height: 180px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}

.catalogImages {
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  background: #ffffff;
  flex: 1;
  max-width: 23.5%;
}

.catalogImages > img {
  height: 180px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}

.profileUrgent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 155px;
  left: 10px;
  background: rgba(255, 0, 0, 0.4);
  border-radius: 5px;
  padding: 5px 10px;
}

.profileUrgent > span {
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.profileBuyMe {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 155px;
  left: 10px;
  background: rgba(36, 111, 255, 0.4);
  border-radius: 5px;
  padding: 5px 10px;
}

.profileBuyMe > span {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
}

.catItems {
  background: #fff;
  padding: 20px;
  border-radius: 13px;
}

@media screen and (max-width: 1150px) {
  .eachMenuCatalog {
    width: 32%;
  }
  .catalogItems {
    gap: 15px 2%;
  }
}

@media screen and (max-width: 1024px) {
  .catalogImages {
    max-width: 32%;
  }
}

@media screen and (max-width: 768px) {
  .changeCat {
    margin-top: 20px;
  }
  .profileTopFavorite {
    top: 170px;
  }
  .catalogImages {
    max-width: 49%;
  }
}

@media screen and (max-width: 550px) {
  .eachMenuCatalog {
    width: 48%;
  }
  .eachMenuCatalog > img {
    height: 124px;
  }
  .profileCatalogUrgent {
    left: 5px;
    top: 95px;
  }
  .profileCatalogUrgent {
    left: 5px;
    top: 95px;
  }
  .profileTopFavorite {
    top: 110px;
  }
}

@media screen and (max-width: 425px) {
  .catalogItems {
    justify-content: space-between;
  }
  .catItems {
    padding: 10px;
  }
  .catalogImages {
    max-width: 49%;
  }
}
