.autoService > h2 {
  cursor: pointer;
}

.autoUrgent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 155px;
  left: 10px;
  background: rgba(255, 0, 0, 0.4);
  border-radius: 5px;
  padding: 3px 10px;
}

.autoUrgent > span {
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.autoServiceSlider {
  display: flex;
  gap: 20px 5%;
  padding: 20px 0;
  flex-wrap: wrap;
}

.eachMenuAuto {
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  background: #ffffff;
  width: 30%;
}

.eachMenuAuto > div {
  position: relative;
}

.eachMenuAuto > div > img {
  height: 180px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  object-fit: cover;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .eachMenuAuto {
    width: 23.5%;
  }
  .autoServiceSlider {
    gap: 15px 2%;
  }
}

@media screen and (max-width: 768px) {
  .buyMe {
    top: 105px;
  }
}

@media screen and (max-width: 578px) {
  .autoServiceSlider {
    gap: 10px 5%;
  }
  .eachMenuAuto > div > img {
    height: 124px;
  }
}