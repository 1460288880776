.littleNavs {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.eachLittleNav {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.eachLittleNav > span {
  line-height: 16px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.2px;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: none !important;
  padding: 0 !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  letter-spacing: 0.2px !important;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding: 0 !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  width: min-content !important;
}

@media screen and (max-width: 1024px) {
  .littleNavs {
    display: none;
  }
}
