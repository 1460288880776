.pop > h1 {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  width: 100%;
}

.callTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.callTop > img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.callTop > h5 {
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #7791f7;
  cursor: pointer;  
}

.callTop > h6 {
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: #969696;
}

.callTop > span {
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: #969696;
}

.callPhone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 20px 10px;
  gap: 10px;
  width: 244px;
  height: 41px;
  background: rgba(119, 145, 247, 0.2);
  border-radius: 10px;
  margin-bottom: 10px;
}

.callPhone > a {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #7791f7;
  text-decoration: none;
}

.CallOther {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.CallOther > span {
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
}
