.downloadApp {
  background: #fff;
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  min-height: 100vh;
}

.downloadApp > img {
  width: 50%;
  height: 100%;
}

.downloadApp > span {
  color: #7791f7;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .downloadApp > img {
    width: 100%;
  }
}
