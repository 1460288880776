.contactSettings > h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 15px;
}

.allMyContacts {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.eachContact {
  background: #ffffff;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 10px;
}

.eachContactConfirmation {
  display: flex;
  align-items: center;
  gap: 30px;
}

.moreIcons {
  cursor: pointer;
  position: relative;
}

.seeMore {
  position: absolute;
  top: -53px;
  right: -15px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  flex-direction: column;
  padding: 5px;
  width: 220px;
}

.seeMore > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 10px;
}

.seeMore > span:hover {
  background: #f5f5f5;
}

.eachContact > span {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.eachContactConfirmation > span {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

@media screen and (max-width: 425px) {
  .eachContactConfirmation {
    gap: 10px;
  }
  .eachContact {
    gap: 20px;
  }
}
