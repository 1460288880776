.eachBlacklist {
  display: flex;
  align-items: center;
  gap: 10px;
}

.eachBlacklist > img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.blackName {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.blackName > h2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7791f7;
}

.blackName > h3 {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #969696;
}

#eachBlack {
  align-items: flex-start;
}
