.registerCheckbox {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

.registerCheckbox > label {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
}

.checkbox {
  /* width: 20px;
  height: 20px; */
  padding: 3px;
  border-radius: 5px;
  border: 2px solid #7791f7;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginTopLeft {
  display: flex;
  justify-content: center;
  align-items: center;
}

.registerText {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 40px 0;
}

.registerText > span {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}

.phoneMask {
  display: flex;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  position: relative;
}

.chooseMask {
  padding: 10px;
  border-right: 1px solid #ebebeb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.phoneMask > input {
  border: none;
  outline: none;
  width: 100%;
  padding: 0 10px;
}

.chooseMask > img {
  width: 25px;
  height: 20px;
  border-radius: 5px;
}

.allPhoneMasks {
  position: absolute;
  top: 40px;
  left: 0;
  width: 200px;
  overflow-y: auto;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.eachPhoneMask {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
}

.eachPhoneMask:hover {
  background: #ebebeb;
}

.eachPhoneMask > img {
  width: 25px;
  height: 20px;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .registerCheckbox > label {
    width: 80%;
  }
}
