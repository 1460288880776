.activePopup {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.25) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 8px 18px rgba(24, 39, 75, 0.12);
  backdrop-filter: blur(20px);
  position: fixed;
  left: 0;
  top: 0;
  display: grid;
  place-items: center;
  z-index: 4;
}

.inactive {
  display: none;
}

.pop {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 8px 18px rgba(24, 39, 75, 0.12);
  padding: 30px;
  background: #fff;
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 20px;
  border-radius: 8px;
  position: relative;
  max-height: calc(100vh - 40px);
}

.close {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 35px;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close:hover {
  background: #f5f5f5;
}

.pop > textarea {
  width: 100%;
  height: 180px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  resize: none;
  margin: 20px 0;
  padding: 10px;
  outline: none;
}

.pop > textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #969696;
}

.sendMessage {
  padding: 9px 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 244px;
  height: 41px;
  background: #7791f7;
  border-radius: 10px;
  cursor: pointer;
}

.sendMessage > span {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .pop {
    width: 60% !important;
    padding: 30px 15px;
  }
}

@media screen and (max-width: 425px) {
  .pop {
    width: 90% !important;
  }
}
