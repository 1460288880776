.footerShadow {
  border-top: 1px solid #ebebeb;
}

.footer {
  width: 92%;
  max-width: 1264px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
}

.eachFooterColumn > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  width: fit-content;
}

.eachFooterColumn > span {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #969696;
}

.FooterSocialMedia {
  display: flex;
  align-items: center;
  gap: 10px;
}

.eachFooterColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.eachFooterColumn > img {
  width: 140px;
  height: 40px;
  cursor: pointer;
}

.FooterSocialMedia > svg {
  cursor: pointer;
}

.mobileCC {
  display: none;
}

.mobileFooterLang {
  display: none;
}

@media screen and (max-width: 768px) {
  .footer {
    width: 90%;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  .mobileC {
    display: none;
  }
  .mobileCC {
    display: flex;
    width: fit-content;
    margin: 0 auto 20px auto;
  }
  .mobileCC > span {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #969696;
  }
  .mobileFooterLang {
    display: flex;
  }
}

@media screen and (max-width: 425px) {
  .mobileCC {
    justify-content: center;
  }
  .eachFooterColumn {
    align-items: center;
  }
  .navPath > ul {
    flex-wrap: wrap;
  }
}
